/* eslint-disable react/prop-types */
import TextField from '@mui/material/TextField';

export default ({
  input: { name, onChange, value, ...restInput },
  inputInput,
  meta,
  helperText,
  ...rest
}: any) => (
  <TextField
    {...rest}
    name={name}
    helperText={meta.touched ? meta.error : helperText}
    error={meta.error && meta.touched}
    inputProps={{ ...inputInput, ...restInput }}
    onChange={onChange}
    value={value}
  />
);
