import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import LoginForm from 'components/LoginForm/LoginForm';
import RecoveryPasswordForm from 'components/RecoveryPasswordForm/RecoveryPasswordForm';
import { login as loginAction, load, logout as logoutAction } from 'redux/modules/auth';
import { notifSend } from 'redux/modules/notifs';
import { resetPwdLong, sendResetPwd } from 'redux/modules/account/authManagement';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconTwitter from 'components/Icons/TwitterFilled';
import FacebookIcon from 'components/Icons/FacebookFilled';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLink } from 'utils/getLink';
import cookie from 'cookies-js';
import qs from 'qs';

const useStyles = makeStyles(
  {
    container: {
      textAlign: 'center',
      marginTop: -64,
      height: '100vh!important',
    },
    card: {
      maxWidth: 345,
    },
    typoColor: {
      color: '#fff',
    },
    buttonRegister: {
      marginTop: 10,
    },
    facebook: {
      background: '#4267b2',
      border: '1px solid #4267b2',
      color: '#fff',
      '&:hover': {
        color: '#4267b2',
      },
    },
    loginTitle: {
      fontFamily: 'wm-marketing-display-font,Georgia,Cambria,"Times New Roman",Times,serif',
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '36px',
      transform: 'translateY(-2px)',
    },
    buttonFacebook: {
      marginBottom: 10,
      backgroundColor: '#3b5998',
      color: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#1d315b',
      },
      '& svg': {
        width: 21,
        height: 21,
        marginRight: 4,
        position: 'relative',
        top: -1,
      },
    },
    buttonTwitter: {
      backgroundColor: '#55acee',
      color: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#006daa',
      },
      '& svg': {
        width: 21,
        height: 21,
        marginRight: 4,
        position: 'relative',
        top: -1,
      },
    },
  },
  { name: 'WMLogin' }
);
// @connect(
//   (state) => ({
//     user: state.auth.user,
//     verifySignUpResult: state.authManagement && state.authManagement.verifySignUpResult,
//     verifySignUpResultError: state.authManagement && state.authManagement.verifySignUpResultError,
//     verifyChangesResult: state.authManagement && state.authManagement.verifyChangesResult,
//     verifyChangesResultError: state.authManagement && state.authManagement.verifyChangesResultError,
//   }),
//   {
//     ...notifActions,
//     ...authActions,
//     resetPwdLongR: resetPwdLong,
//     sendResetPwdR: sendResetPwd,
//   }
// )
const Login = (): React.ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    recovery: false,
  });
  const intl = useIntl();
  const {
    verifySignUpResult,
    verifySignUpResultError,
    verifyChangesResult,
    verifyChangesResultError,
    user,
  } = useSelector((reduxState) => {
    return {
      user: (reduxState as any).auth.user,
      verifySignUpResult:
        (reduxState as any).authManagement && (reduxState as any).authManagement.verifySignUpResult,
      verifySignUpResultError:
        (reduxState as any).authManagement &&
        (reduxState as any).authManagement.verifySignUpResultError,
      verifyChangesResult:
        (reduxState as any).authManagement &&
        (reduxState as any).authManagement.verifyChangesResult,
      verifyChangesResultError:
        (reduxState as any).authManagement &&
        (reduxState as any).authManagement.verifyChangesResultError,
    };
  });

  const successLogin = () => {
    dispatch(
      notifSend({
        // eslint-disable-next-line quotes
        message: "You're logged !",
        kind: 'success',
        dismissAfter: 10000,
      })
    );
  };

  const login = async (data: any) => {
    const result = await dispatch(loginAction('local', data));
    successLogin();
    return result;
  };

  const logout = () => {
    dispatch(logoutAction());
  };

  const recovery = async (data: any) => {
    const result = data.token
      ? await dispatch(resetPwdLong(data.token, data.password))
      : await dispatch(sendResetPwd(data.email));
    if (data.token) {
      dispatch(
        notifSend({
          message: 'Your password is restored',
          kind: 'success',
          dismissAfter: 10000,
        })
      );
      navigate('/login');
    } else {
      dispatch(
        notifSend({
          message: 'We send you a email with instructions',
          kind: 'success',
          dismissAfter: 10000,
        })
      );
    }

    return result;
  };

  useEffect(() => {
    if (location.search.indexOf('redirect') !== -1) {
      cookie.set('redirect', location.search);
    }

    if (user && cookie.get('redirect')) {
      const cquery = cookie.get('redirect');
      try {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        const query = qs.parse(cquery, { ignoreQueryPrefix: true });
        successLogin();
        navigate((query.redirect as string) || '/login-success');
      } catch (e) {
        console.error(e);
      }
    }

    if (!verifySignUpResultError && verifySignUpResult) {
      dispatch(
        notifSend({
          message:
            'Your email has been verified. We can now protect your account and you can login.',
          kind: 'success',
          dismissAfter: 10000,
        })
      );
    } else if (verifySignUpResultError) {
      dispatch(
        notifSend({
          message: 'Sorry, but we could not verify your email.',
          kind: 'error',
          dismissAfter: 10000,
        })
      );
    }

    if (!verifyChangesResultError && verifyChangesResult) {
      dispatch(load());
      dispatch(
        notifSend({
          message: 'You have approved the changes to your account.',
          kind: 'success',
          dismissAfter: 10000,
        })
      );
    } else if (verifyChangesResultError) {
      dispatch(
        notifSend({
          message: `Sorry, but we could not approved the changes to your account: ${
            verifyChangesResultError.message || ''
          }`,
          kind: 'error',
          dismissAfter: 10000,
        })
      );
    }
  }, []);

  const { recovery: isRecovery } = state;

  let title = intl.formatMessage({ id: 'login', defaultMessage: 'Login' });

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isLoadingAuth = query.connect || location.hash.indexOf('access_token=') !== -1;
  if (isLoadingAuth) {
    title = 'Loading session';
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={`headerBackground ${classes.container}`}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <link rel="canonical" href={getLink('/login', false)} />
      </Helmet>
      {(isRecovery || params.type === 'reset') && (
        <Grid item>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.loginTitle} variant="h1" gutterBottom>
                <FormattedMessage id="recoveryPassword" defaultMessage="Recovery password" />
              </Typography>
              <RecoveryPasswordForm
                token={params.type === 'reset' && params.slug}
                onSubmit={recovery}
              />
            </CardContent>
          </Card>
          {params.type !== 'reset' && (
            <Button
              onClick={() => {
                setState({ recovery: false });
              }}
            >
              <FormattedMessage id="login" defaultMessage="Login" />
            </Button>
          )}
        </Grid>
      )}
      {!isRecovery && params.type !== 'reset' && (
        <Grid item>
          <Card className={classes.card}>
            <CardContent>
              {!user && !isLoadingAuth && (
                <Typography className={classes.loginTitle} variant="h1">
                  <FormattedMessage id="login" defaultMessage="Login" />
                </Typography>
              )}
              {!user && !isLoadingAuth && (
                <div>
                  <LoginForm onSubmit={login} />
                  <Button
                    fullWidth
                    className={classes.buttonFacebook}
                    variant="contained"
                    onClick={() => {
                      window.location.href = '/oauth/facebook';
                    }}
                  >
                    <FacebookIcon />{' '}
                    <FormattedMessage
                      id="loginWith"
                      defaultMessage="Login with {value}"
                      values={{
                        value: 'Facebook',
                      }}
                    />
                  </Button>
                  <Button
                    fullWidth
                    className={classes.buttonTwitter}
                    variant="contained"
                    onClick={() => {
                      window.location.href = '/oauth/twitter';
                    }}
                  >
                    <IconTwitter />{' '}
                    <FormattedMessage
                      id="loginWith"
                      defaultMessage="Login with {value}"
                      values={{
                        value: 'Twitter',
                      }}
                    />
                  </Button>
                  <Button
                    component={Link}
                    to={`/register${location.search}`}
                    className={classes.buttonRegister}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                  >
                    <FormattedMessage id="createAccount" defaultMessage="Create account" />
                  </Button>
                </div>
              )}
              {isLoadingAuth && (
                <>
                  <Typography paragraph>Connecting with your account...</Typography>
                  <div>
                    <CircularProgress className={(classes as any).progress} />
                  </div>
                </>
              )}
              {user && (
                <>
                  <br />
                  <Typography paragraph>
                    You are currently logged in as {user.name || user.email}.
                  </Typography>

                  <div>
                    <Button variant="contained" color="secondary" onClick={logout}>
                      <FormattedMessage id="signOut" defaultMessage="Sign out" />
                    </Button>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
          <Button
            onClick={() => {
              setState({ recovery: true });
            }}
          >
            <FormattedMessage id="recoveryPassword" defaultMessage="Recovery password" />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Login;
