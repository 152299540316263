import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FaSignInAlt as RegisterIcon } from 'react-icons/fa';
import LinearProgress from '@mui/material/LinearProgress';
import { TextField } from 'components/MaterialFinalFormFields';
import { FormattedMessage, useIntl } from 'react-intl';
import loginValidation from './loginValidation';

const useStyles = makeStyles(
  {
    buttonLogin: {
      marginBottom: 10,
      marginTop: 10,
    },
    containerError: {
      marginTop: 10,
    },
  },
  { name: 'WMLogin' }
);

const LoginForm = ({ onSubmit }: { onSubmit: (values: any) => any }): React.ReactElement => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Form
      onSubmit={(values) =>
        onSubmit(values).then(
          () => {
            return null;
          },
          (err: Error | any) => err
        )
      }
      validate={loginValidation}
      render={({ handleSubmit, submitError, submitting }) => (
        <form method="post" onSubmit={handleSubmit}>
          <Grid spacing={1} container>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                name="email"
                type="text"
                fullWidth
                label={intl.formatMessage({
                  id: 'Email',
                  defaultMessage: 'Email',
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                name="password"
                type="password"
                autoComplete="current-password"
                fullWidth
                label={intl.formatMessage({
                  id: 'Password',
                  defaultMessage: 'Password',
                })}
              />
              {submitError && (
                <Typography className={classes.containerError} color="error">
                  {submitError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {submitting && <LinearProgress color="secondary" />}
              <Button
                className={classes.buttonLogin}
                fullWidth
                variant="outlined"
                color="secondary"
                type="submit"
              >
                <RegisterIcon style={{ marginRight: 10 }} />
                <FormattedMessage id="login" defaultMessage="Login" />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
