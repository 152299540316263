/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@mat... Remove this comment to see the full error message
import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

export default (props: any) => {
  const Icon = createSvgIcon(
    <path d="M18.26 10.55c0-4.302-3.47-7.79-7.75-7.79-4.28 0-7.75 3.488-7.75 7.79a7.773 7.773 0 0 0 6.535 7.684v-5.49h-1.89v-2.2h1.89v-1.62c0-1.882 1.144-2.907 2.814-2.907.8 0 1.48.06 1.68.087V8.07h-1.15c-.91 0-1.09.435-1.09 1.07v1.405h2.16l-.28 2.2h-1.88v5.515c3.78-.514 6.7-3.766 6.7-7.71" />,
    'FacebookFilled'
  );
  return <Icon {...props} viewBox="0 0 21 21" />;
};
